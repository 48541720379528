<template>
  <div class="orderDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="form" label-width="80px">
      <el-form-item label="订单号：">
        <span>{{ orderDetail.orderCoding }}</span>
      </el-form-item>
      <el-form-item label="下单时间：">
        <span>{{ orderDetail.orderAddTime }}</span>
      </el-form-item>
      <el-form-item label="会员姓名：">
        <span>{{ orderDetail.memberName }}</span>
      </el-form-item>
      <el-form-item label="会员手机：">
        <span>{{ orderDetail.orderPhone }}</span>
      </el-form-item>
      <el-form-item label="实际支付价格：">
        <span>{{ orderDetail.orderTotalPrice }}</span>
      </el-form-item>
      <el-form-item label="是否已支付：">
        <span>{{ orderDetail.orderIsPay }}</span>
      </el-form-item>
      <el-form-item
        label="付款方式："
        v-if="orderDetail.orderIsPay === '已支付'"
      >
        <span>{{ orderDetail.orderWay }}</span>
      </el-form-item>
      <el-form-item label=" 预约时间：">
        <span>{{ orderDetail.beginTime }}</span>
      </el-form-item>
      <el-form-item label="订单状态：">
        <span>{{ orderDetail.orderStatus }}</span>
      </el-form-item>
      <el-form-item label="订单类型：">
        <span>{{
          type == 1
            ? "酒店订单"
            : type == 2
            ? "门票订单"
            : type == 3
            ? "套餐订单"
            : ""
        }}</span>
      </el-form-item>
      <el-form-item
        label="确认状态："
        v-if="orderDetail.orderIsPay === '已支付'"
      >
        <span>{{ orderDetail.orderConfirmStatus }}</span>
        <el-button
          v-if="orderDetail.orderConfirmStatus === '未确认'"
          @click="onconfirm"
          style="margin-left: 20px"
          type="primary"
          >确认订单</el-button
        >
      </el-form-item>
      <el-form-item label="客户备注：">
        <span>{{ orderDetail.orderRemark }}</span>
      </el-form-item>
      <el-form-item label="管理员备注：">
        <span>{{ orderDetail.orderManageRemark }}</span>
        <el-button style="margin-left: 20px" type="primary" @click="onAddRemark"
          >添加备注</el-button
        >
      </el-form-item>
      <el-form-item label="团队状态：">
        <span>{{ orderDetail.teamStatus }}</span>
      </el-form-item>
      <el-form-item label="成团人数：">
        <span>{{ orderDetail.teamCount }}</span>
      </el-form-item>
      <el-form-item label="成团剩余人数：">
        <span>{{ orderDetail.teamRemainCount }}</span>
      </el-form-item>
      <el-form-item label="剩余时间：">
        <span>{{ orderDetail.remainTime }}</span>
      </el-form-item>
      <el-form-item label="取票人/联系人：">
        <template>
          <div
            class="peoples"
            v-for="(item, index) in orderDetail.peoples"
            :key="index"
          >
            <span v-if="item.travelers_linkMan">姓名：</span>
            <span>{{ item.travelers_linkMan || "" }}</span>
            <span v-if="item.travelers_phone">电话：</span>
            <span>{{ item.travelers_phone || "" }}</span>
            <span v-if="item.travelers_cert_type"
              >{{
                (item.travelers_cert_type || "") == 1
                  ? "身份证"
                  : (item.travelers_cert_type || "") == 2
                  ? "港澳通行证"
                  : (item.travelers_cert_type || "") == 3
                  ? "护照"
                  : ""
              }}：</span
            >
            <span>{{ item.travelers_cert_no || "" }}</span>
          </div>
        </template>
      </el-form-item>
    </el-form>
    <div class="title">
      <div></div>
      <div>商品信息</div>
    </div>
    <el-table :data="[orderDetail]" style="width: 100%" border>
      <el-table-column
        align="center"
        prop="hotelImg"
        label="产品图片"
        width="width"
      >
        <template slot-scope="{ row }">
          <img src="" alt="" />
          <img style="width: 100px; height: 100px" :src="row.hotelImg" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="hotelName"
        label="产品名称"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="hotelSecondName"
        :label="
          Number(type) == 1
            ? '客房名称'
            : Number(type) == 2
            ? '门票名称'
            : Number(type) == 3
            ? '套餐名称'
            : ''
        "
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="orderTotalPrice"
        label="产品总价"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="hotelCount"
        label="购买数量"
        width="width"
      >
      </el-table-column>
    </el-table>
    <div class="title">
      <div></div>
      <div>团队信息</div>
    </div>
    <el-table border :data="orderDetail.teams" style="width: 100%">
      <el-table-column align="center" prop="headImg" label="头像" width="width">
        <template slot-scope="{ row }">
          <img :src="row.headImg" style="width: 50px; height: 50px" alt="" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="nickName"
        label="昵称"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="isTeam"
        label="是否团长"
        width="width"
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="isPay"
        label="是否支付"
        width="width"
      >
      </el-table-column>
    </el-table>
    <el-button
      class="btn"
      type="primary"
      @click="$router.go(-1)"
      style="margin-left: 80px"
      >返回</el-button
    >
    <el-dialog
      title="添加备注"
      :visible.sync="dialogVisible"
      @close="onColse"
      width="30%"
    >
      <el-input
        type="textarea"
        rows="6"
        v-model="remark"
        placeholder="请输入订单备注"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  groupOrderDetail,
  confirmOrder,
  orderAddRemark,
} from "../../api/order";
export default {
  name: "orderDetail",
  props: {
    orderId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      title: "订单详情",
      orderDetail: {},
      type: null,
      dialogVisible: false,
      remark: "",
    };
  },
  watch: {
    orderId() {
      this.getorderdetail();
    },
  },
  computed: {},
  created() {
    this.getorderdetail();
  },
  methods: {
    onconfirm() {
      this.$confirm("是否确认订单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await confirmOrder({
            orderId: this.orderDetail.orderId,
          });
          if (data.code == 0) {
            this.$message.success(data.msg);
            this.getorderdetail();
          } else {
            this.$message.error(data.msg);
          }
          this.dialogVisible = false;
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    async onOk() {
      const { data } = await orderAddRemark({
        orderId: this.orderDetail.orderId,
        orderRemark: this.remark,
      });
      if (data.code == 0) {
        this.$message.success(data.msg);
        this.getorderdetail();
      } else {
        this.$message.error(data.msg);
      }
      this.dialogVisible = false;
    },
    onAddRemark() {
      this.dialogVisible = true;
    },
    onColse() {
      this.remark = "";
    },
    async getorderdetail() {
      const { data } = await groupOrderDetail({
        orderId: Number(this.orderId),
      });
      if (data.code == 0) {
        if (data.data.peoples[0] === null) {
          data.data.peoples = [
            {
              travelers_linkMan: "",
              travelers_phone: "",
              travelers_cert_type: "",
              travelers_cert_no: "",
            },
          ];
        }
        if (data.data.remainTime < 0) {
          data.data.remainTime = "已结束";
        } else {
          data.data.remainTime = this.formatMilliseconds(data.data.remainTime);
        }
        this.type = data.msg;
        this.orderDetail = data.data;
      } else {
        this.$message.error(data.msg);
      }
    },
    formatMilliseconds(value) {
      var second = parseInt(value) / 1000; // second
      var minute = 0; // minute
      var hour = 0; // hour
      if (second > 60) {
        minute = parseInt(second / 60);
        second = parseInt(second % 60);
        if (minute > 60) {
          hour = parseInt(minute / 60);
          minute = parseInt(minute % 60);
        }
      }
      var result;
      if (second >= 10) {
        result = "" + parseInt(second);
      } else {
        result = "" + "0" + parseInt(second);
      }
      if (minute >= 10) {
        result = "" + parseInt(minute) + ":" + result;
      } else {
        result = "" + "0" + parseInt(minute) + ":" + result;
      }
      if (hour >= 10) {
        result = "" + parseInt(hour) + ":" + result;
      } else {
        result = "" + "0" + parseInt(hour) + ":" + result;
      }
      return result;
    },
  },
};
</script>
<style lang="less" scoped>
.orderDetail {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  /deep/ .el-form {
    margin-top: 50px;
    .el-form-item {
      .el-form-item__label {
        width: 200px !important;
      }
      .el-form-item__content {
        margin-left: 200px !important;
        .peoples {
          span {
            &:nth-child(2n) {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  .el-table {
    margin-top: 20px;
  }
  .title {
    display: flex;
    align-items: center;
    margin-top: 20px;
    div {
      &:nth-child(1) {
        width: 5px;
        height: 13px;
        background: #6a8abe;
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
  .btn {
    margin-top: 50px;
  }
}
</style>